import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITSozlesmeTipi_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITSozlesmeTipi_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SozlesmeTipiSelectById: any[];
  SozlesmeTipiSelectById_dummy: any[];
  SozlesmeTipiSave: number;
  SozlesmeTipiSave_dummy: number;
}

export class TSozlesmeTipi_Form_ScreenBase extends React.PureComponent<ITSozlesmeTipi_Form_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "f20f22bf-a506-4dbb-8764-94b98de39e38",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 412319, PropertyName: "value", Value: "Sözleşme Tipi Form" },
        { Id: 8355855, PropertyName: "value", Value: "Tanim" },
        { Id: 42268150, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 5249149, PropertyName: "value", Value: "Kod" },
        { Id: 90082130, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 7501892, PropertyName: "value", Value: "Order by" },
        { Id: 9544, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tsozlesmetipi_form_42268150_value_kuikaTextInputRef = React.createRef();
    this.tsozlesmetipi_form_90082130_value_kuikaTextInputRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SozlesmeTipiSelectById: [],
      SozlesmeTipiSave: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tsozlesmetipi_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TSozlesmeTipi_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tsozlesmetipi_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tsozlesmetipi_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TSozlesmeTipi_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      tsozlesmetipi_form_42268150_value: this.state.SozlesmeTipiSelectById?.at?.(0)?.tanim ?? undefined,
      tsozlesmetipi_form_90082130_value: this.state.SozlesmeTipiSelectById?.at?.(0)?.kod ?? undefined,
      tsozlesmetipi_form_36513860_value: this.state.SozlesmeTipiSelectById?.at?.(0)?.orderBy ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TSozlesmeTipi_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSozlesmeTipi_Form/TSozlesmeTipi_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SozlesmeTipiSelectById = result?.data.sozlesmeTipiSelectById;
    formVars.tsozlesmetipi_form_42268150_value = ReactSystemFunctions.toString(
      this,
      stateVars.SozlesmeTipiSelectById?.length > 0
        ? stateVars.SozlesmeTipiSelectById[0].tanim
        : this.state.SozlesmeTipiSelectById?.length > 0
        ? this.state.SozlesmeTipiSelectById[0].tanim
        : null
    );
    formVars.tsozlesmetipi_form_90082130_value = ReactSystemFunctions.toString(
      this,
      stateVars.SozlesmeTipiSelectById?.length > 0
        ? stateVars.SozlesmeTipiSelectById[0].kod
        : this.state.SozlesmeTipiSelectById?.length > 0
        ? this.state.SozlesmeTipiSelectById[0].kod
        : null
    );
    formVars.tsozlesmetipi_form_36513860_value = ReactSystemFunctions.toString(
      this,
      stateVars.SozlesmeTipiSelectById?.length > 0
        ? stateVars.SozlesmeTipiSelectById[0].orderBy
        : this.state.SozlesmeTipiSelectById?.length > 0
        ? this.state.SozlesmeTipiSelectById[0].orderBy
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSozlesmeTipi_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSozlesmeTipi_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.tsozlesmetipi_form_42268150_value = ReactSystemFunctions.toString(
      this,
      stateVars.SozlesmeTipiSelectById?.length > 0
        ? stateVars.SozlesmeTipiSelectById[0].tanim
        : this.state.SozlesmeTipiSelectById?.length > 0
        ? this.state.SozlesmeTipiSelectById[0].tanim
        : null
    );

    formVars.tsozlesmetipi_form_90082130_value = ReactSystemFunctions.toString(
      this,
      stateVars.SozlesmeTipiSelectById?.length > 0
        ? stateVars.SozlesmeTipiSelectById[0].kod
        : this.state.SozlesmeTipiSelectById?.length > 0
        ? this.state.SozlesmeTipiSelectById[0].kod
        : null
    );

    formVars.tsozlesmetipi_form_36513860_value = ReactSystemFunctions.toString(
      this,
      stateVars.SozlesmeTipiSelectById?.length > 0
        ? stateVars.SozlesmeTipiSelectById[0].orderBy
        : this.state.SozlesmeTipiSelectById?.length > 0
        ? this.state.SozlesmeTipiSelectById[0].orderBy
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TSozlesmeTipi_FormComponent_5267_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSozlesmeTipi_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      Kod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsozlesmetipi_form_90082130_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsozlesmetipi_form_36513860_value", "value", "", "", "")
        ),
        "number"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsozlesmetipi_form_42268150_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TSozlesmeTipi_Form/TSozlesmeTipi_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SozlesmeTipiSave = result?.data.sozlesmeTipiSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSozlesmeTipi_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSozlesmeTipi_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
